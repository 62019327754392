$color_1: #444;
$color_2: #fff;
$color_3: #222;
$color_4: #000;
$color_5: #333;
$color_6: #c81e28;
$color_7: #ffffff;
$font-family_1: sans-serif;
$font-family_2: outfit, sans-serif;
$background-color_1: #fff;
$background-color_2: #444;
$background-color_3: #f8f8f8;
$background-color_4: #c81e29;