@import "variables";
@import "https://fonts.googleapis.com/css?family=Outfit:300,400,500,600,700&display=swap";
body {
  padding: 0;
  margin: 0;
}

.wrapper {
  font-family: $font-family_1;
  text-align: center;
}

h1, h2, h3, h4, h5 h6 {
  color: $color_3;
  font-family: $font-family_2;
  font-weight: 700;
  line-height: 1.2;
}



.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: $color_3;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.navbar-link {
  padding: 14px 10px;
  flex: 0 auto;
  transition: 150ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  color: $color_4;
  line-height: 20.16px;
  font-weight: 500;
  user-select: none;
  &:hover {
    color: $color_6;
    text-decoration: none;
  }
}
a {
  color: $color_5;
  outline: 0;
  &:visited {
    color: $color_5;
    outline: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
    color: #222;
    font-family: outfit, sans-serif;
    font-weight: 700;
    line-height: 1.2;
}

.desk-navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 5px 30px;
  background-color: $background-color_1;
  box-shadow: 0 1px 4px #01162740;

  .container.navbar {
	position: relative;
	display: flex;
	max-width: 1390px;
	padding: 5px 0 5px 0;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	grid-row-gap: 20px;
	line-height: 20.16px;
	font-weight: 500;
  }
}

.tabs-component {
  [role="tablist"] {
    display: flex;
	align-items: center;
	justify-content: center;
    position: relative;
    padding: 10px;
	max-width: 1080px;
	margin: 0 auto;
    button {
      border: none;
      text-align: center;
      background: #eff1f2;
      margin: 5px;
      letter-spacing: 1px;
      position: relative;
      display: block;
      border-radius: 5px 5px 5px 5px;
      width: 12.5rem;
      min-width: 150px;
      max-width: 225px;
      padding: 0.7rem 0.65rem;
      cursor: pointer;
      z-index: 10;
      transition: 250ms;
      user-select: none;
      font-weight: 300;

	  align-self: center;
      &:focus {
        background-color: $background-color_4 !important;
        color: $color_7;
      }
      &:hover {
        background-color: $background-color_4 !important;
        color: $color_7;
      }
    }
    button.active {
      background-color: $background-color_4 !important;
      color: $color_7;
    }
  }
  [role="tabpanel"] {
    text-align: center;

    #top-banner {
      display: flex;
      box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);
    }
    .ad-container {
      display: flex;
      justify-content: center;
	  width: 100%;
      min-height: 90px;
      max-width: 1080px;
      margin: 0 auto;
	  margin-top: 25px;
	  margin-bottom: 25px;
      position: relative;
      background-color: $background-color_3;
      border-radius: 4px;
      padding: 8px 0px;
	  box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);
    }
    #content-container {
      width: 1080px;
      padding: 25px;
      margin: 0 auto;
      background-color: $background-color_3;
      box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);
      border-radius: 4px;
      .grid-container {
        display: grid;
        grid-gap: 1em;
        grid-template-columns: [col1-start] 330px [col2-start] 330px [col3-start] 340px [col3-end];
        grid-template-rows: [row1-start] auto [row2-start] auto [row3-start] auto [row4-start] auto [row5-start] auto [row4-end];
        color: $color_1;
        margin: 0 auto;
        justify-content: center;

        .grid-item {
          background-color: $background-color_2;
          color: $color_2;
          border-radius: 5px;
          font-size: 150%;

          justify-content: center;
          display: flex;
          align-items: center;
        }

        .a {
          grid-column: col1-start / col3-end;
          grid-row: row1-start;
          height: 250px;
        }
        .b {
          grid-column: col3-start;
          grid-row: row2-start / row2-end;
          height: 620px;
        }
        .c {
          grid-column: col1-start / col3-start;
          grid-row: row2-start;
          height: 300px;
        }
        .d {
          grid-column: col1-start / col3-start;
          grid-row: row3-start;
          height: 300px;
        }
        .e {
          grid-column: col1-start / col3-end;
          grid-row: row5-start;
          height: 250px;
        }
      }
    }
  }
}
#footer {
  margin-top: 100px;
  background-color: white;
}

.hb-demo-2 {
  background-color: unset !important;
  box-shadow: unset !important;
}